import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function MachineDetails() {
  const [machineDetails, setMachineDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { machineQRCode } = useParams();
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    setLoading(true);
    axios.get(`https://canwash.ainsoftitservices.uk/api/machine/${machineQRCode}`) // Adjust the API URL
      .then(response => {
        setMachineDetails(response.data);
        setLoading(false);
      })
      .catch(err => {
        setError('Failed to fetch machine details');
        setLoading(false);
        console.error(err);
      });
  }, [machineQRCode]);

  const handleProgramSelect = (programId) => {
    // Redirect to payment page with both the machine QR Code and the program ID
    navigate(`/payment/${machineQRCode}/${programId}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!machineDetails) return <div>No machine data available.</div>;

  return (
    <div class="col-md-4">
      <h1 class="text-center">Machine Programs</h1>
      <ul>
        {machineDetails.map((program) => (
          <div class="row mb-3">
            <button class="btn btn-primary" onClick={() => handleProgramSelect(program.id)}>
              {program.name} - {program.price} - {program.temperature}°C
            </button>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default MachineDetails;
