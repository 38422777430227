import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

function PaymentPage() {
  const { machineQRCode, programId } = useParams();
  const [programDetails, setProgramDetails] = useState(null);

  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetch(`https://canwash.ainsoftitservices.uk/api/machine/${machineQRCode}/program/${programId}`) // Adjust the API URL
      .then(res => res.json())
      .then(data => {
        setProgramDetails(data);
        setLoading(false);
      })
      .catch(err => {
        setError('Failed to fetch program details');
        setLoading(false);
      });
  }, [machineQRCode, programId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setMessage('Stripe has not initialized yet.');
      return;
    }
    //setLoading(true);
    const cardElement = elements.getElement(CardElement);
  
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
  
    if (error) {
      setMessage(error.message);
      setLoading(false);
    } else {
      const response = await fetch('https://canwash.ainsoftitservices.uk/api/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: programDetails.price, // Use the price from the fetched program details
          programId: programId, // Include the program ID
          machineQRCode: machineQRCode, // Include the machine QR Code ID
          customerName: customerName, // Include customer name
          customerEmail: customerEmail // Include customer email
        }),
      });

      const result = await response.json();
     // setLoading(false);
  
      if (result.success) {
        setMessage('Payment successful!');
      } else {
        setMessage('Payment failed: ' + (result.error.message || 'Unknown Error'));
      }
    }
  };
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!programDetails) return <div>No program details available.</div>;

  return (
    <div class="col-md-4 ms-2 me-2">
      <h1 class="text-center">Payment for Program: {programDetails.name}</h1>
      <p>Price: €{programDetails.price / 100} ({programDetails.temperature}°C)</p>
      <form onSubmit={handleSubmit}>
      <div style={{ marginBottom: '20px' }}>
          <input
            class="form-control"
            type="text"
            placeholder="Your Name"
            value={customerName}
            required
            onChange={(e) => setCustomerName(e.target.value)}
            style={{ width: '100%', padding: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <input
            class="form-control"
            type="email"
            placeholder="Your Email"
            value={customerEmail}
            required
            onChange={(e) => setCustomerEmail(e.target.value)}
            style={{ width: '100%', padding: '10px' }}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <CardElement />
        </div>
        <button class="btn btn-primary center" type="submit" disabled={!stripe}>
          Pay €{programDetails.price / 100}
        </button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
}

export default PaymentPage;
