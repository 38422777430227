import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm.js';
import MachineDetails from './MachineDetails.js';
import PaymentPage from './PaymentPage.js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (

    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        {/* <Route path="/:machineQRCode" element={<PaymentForm />} /> */}

        <Route path="/:machineQRCode" element={
          <Elements stripe={stripePromise}>
            <MachineDetails />
          </Elements>
        } />
        <Route path="/payment/:machineQRCode/:programId" element={
          <Elements stripe={stripePromise}>
            <PaymentPage />
          </Elements>
        } />
{/* 
      <Route path="/payment/:machineQRCode/:programId" element={<PaymentPage />} /> */}

      </Routes>
    </Router>

    // <div className="App">
    //   <header className="App-header">
    //     <div>
    //     <Elements stripe={stripePromise}>
    //       <PaymentForm />
    //     </Elements>
    //   </div>
    //   </header>

    // </div>
  );
}

export default App;
